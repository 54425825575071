<template>

<div class="creationCompetition" >
  <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left:11px" v-show="show">
  <el-breadcrumb-item :to="{ path: '/' }">直播实训题管理</el-breadcrumb-item>
  <el-breadcrumb-item>商品库</el-breadcrumb-item>
  <el-breadcrumb-item>创建商品</el-breadcrumb-item>
</el-breadcrumb>
    <div class="creationCompetitionProgress" v-show="show">
      <div class="competitionProgress">
        <div class="step-num">
          <span class="active">01</span>
          <span>设置商品信息</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 1
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 1 ? 'active' : ''">02</span>
          <span>设置商品数据</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 2
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 2 ? 'active' : ''">03</span>
          <span>设置脚本数据</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 3
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 3 ? 'active' : ''">04</span>
          <span>设置商品优惠卷</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 4
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 4 ? 'active' : ''">05</span>
          <span>设置商品互动问答</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 5
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
         <div class="step-num">
          <span :class="active > 5 ? 'active' : ''">06</span>
          <span>设置商品脚本分类</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 6
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active >= 7 ? 'active' : ''">07</span>
          <span>创建成功</span>
        </div>
        
        
      </div>
    </div>
    <div
      class="creationCompetitionForm"
      style="height:100%;flex: 1;min-height: calc(100vh - 330px)"
    >
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  name: "creationScript",
  data() {
    return {
      activeName: "raceAdmin",
      active: 1,
      show:true
    };
  },
  watch: {
    $route(route) {
    if(this.$route.name == "zero"){
      this.show = false
    }else if(this.$route.name == "one"){
        this.show = true
    } else if (this.$route.name == "two") {
        this.active = 2;
        this.show = true
      } else if (this.$route.name == "three") {
        this.active = 3;
      } else if (this.$route.name == "four") {
        this.active = 4;
      }else if(this.$route.name == "five"){
         this.active = 5;
      } else if(this.$route.name == "six"){
         this.active = 6;
      }else if(this.$route.name == "seven"){
         this.active = 7;
      } else {
        this.active = 1;
      }
    },
  },
  created() {
   if(this.$route.name == "zero"){
      this.show = false
    }else if(this.$route.name == "one"){
        this.show = true
    } else if (this.$route.name == "two") {
        this.active = 2;
        this.show = true
      } else if (this.$route.name == "three") {
        this.active = 3;
      } else if (this.$route.name == "four") {
        this.active = 4;
      }else if(this.$route.name == "five"){
         this.active = 5;
      } else if(this.$route.name == "six"){
         this.active = 6;
      }else if(this.$route.name == "seven"){
         this.active = 7;
      }
      else {
        this.active = 1;
      }
    },
};
</script>

<style scoped lang="scss">
.creationCompetition {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 31px;
  overflow: hidden;

  .creationCompetitionProgress {
    display: flex;
    .competitionProgress {
      margin: 54px auto;
      display: flex;
      align-items: center;
      .step-num {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;  
        span:nth-child(1) {
          display: inline-block;
          width: 30px;
          height: 30px;
          font-size: 15px;
          color: #ffffff;
          background: #dcdcdc;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
        }
        .active {
          background: #1122d8 !important;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 100px;
          text-align: center;
          top: 40px;

          position: absolute;
          font-size: 12px;
          color: #333333;
        }
      }
      .step-wire {
        height: 9px;
        margin: 0 10px;
      }
    }
  }
}
</style>